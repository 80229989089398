import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grecaptcha-v2"
export default class extends Controller {
  static values = {
    sk: String,
  }
  initialize() {
    
  }
  connect() {
    let controller = this
    

    if (controller.hasSkValue) {
      grecaptcha.render("grecaptchaV2", {
        'sitekey': controller.skValue,
        'callback': controller.recaptchaVerified,
        'expired-callback': controller.recaptchaExpired
      })  
    }
  }

  recaptchaVerified(token) {
    //console.log(grecaptcha.getResponse())
    //console.log(token)
    document.getElementById("submitBtn").disabled = false; // Enable button
  }

  recaptchaExpired() {
    document.getElementById("submitBtn").disabled = true; // Disable button again
  }
}
